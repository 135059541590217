@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px; /*1rem = 16px*/
    body {
        font-family: 'Roboto', Helvetica, sans-serif !important;
        background-color: #eff1f5;
    }
}

.heading {
    font-size: 1rem !important;
    font-weight: 500 !important;
    margin-bottom: 1rem !important;
    text-align: center !important;
}

#root {
    overflow-x: hidden;
}
