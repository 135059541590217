@keyframes zoomInOut {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes slideHide {
    0% {
        height: '30rem';
    }
    100% {
        height: 0;
    }
}
@keyframes slideShow {
    0% {
        max-height: 0;
    }
    100% {
        max-height: '30rem';
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@keyframes slideup {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
}
