$gray: #5e5e5e;
$text-color: #707070;
$green: #3179be;
$light-gray: #dedede;
$lightGray: #e4e4e4e4;
$white: #fff;
$usrProBg: #3079be;
$appStoreCol: #75787d;
$staticColor: #3179be;
$currentBg: #9cb6aa;
$black: #000;
$gray2: #a3a3a3;
$greenDark: #4b8553;
$light-gray:#e6e6e6;
$light-green: #3179be;
$sky-light: #e5e5ea;
$darkyellow: #ffb05d;
$cardBgColor: #f3f3f3;
$income-heading: rgb(60, 213, 194);
$darkIncomeTile: rgb(41, 154, 139);
$darkslaty: #299A8B;
$inputGray:#cecdcc;
