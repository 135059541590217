ul {
  list-style: none;
  padding: 0;
}
.hide {
  display: none;
}
.show {
  display: block !important;
}

.social-icon-group {
  padding: 10px 0;
}

@media screen and (max-width: 768px) {
  .dashboard-navigation ul li {
    padding: 10px 22px;
  }
  .mr-tab {
    margin-top: 30px;
  }
}

@media screen and (min-width: 430px) and (max-height: 355px) {
  .Main-img,
  .header,
  .Main-img-login {
    height: auto !important;
  }
}
