@mixin flex($align, $content, $direction) {
    display: flex;
    align-items: $align;
    justify-content: $content;
    flex-direction: $direction;
}

@mixin borderRadius {
    border-radius: 0.3rem;
}

@mixin buttonBoxShadow {
    box-shadow: 0px 6px 13px 2px rgba(100, 182, 255, 0.4);
}

@mixin cardShadow {
    box-shadow: 0 4px 20px -1px rgba(0, 0, 0, 0.03);
}

@mixin disableScroll {
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
}

@mixin greenBtn {
    background-color: $light-green;
    color: $white;
    font-size: 0.8rem;
    padding: 0.5rem 0.9rem;
    border-radius: 0.3rem;
    font-weight: 100;
    text-transform: capitalize;
    &:hover{
        background-color:#318e3e;
    }
}
