.gm-style-iw-c {
    background-color: $green !important;
    border-radius: 0 !important;
    &::after {
        background: $green !important;
    }
}
.gm-style-iw-t {
    &::after {
        background: $green !important;
    }
}
.gm-ui-hover-effect {
    display: none !important;
}

.gm-style-iw.gm-style-iw-c {
    padding: 0 !important;
    max-width: unset !important;
    max-height: unset !important;
    min-width: unset !important;
    overflow: unset !important;
    color: $white;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}
.gm-style-iw-d {
    overflow: hidden !important;
}

.sliderWrapper {
    button[type='button'] img {
        background-color: $staticColor !important;
        border-radius: 50%;
    }
}
