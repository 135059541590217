.component-wrapper {
    @include flex(center, space-between, column);
    // height: 100vh;
    overflow: auto;
    background-color: $white;
    @include disableScroll;
    .form-container {
        background: url('../../theme/assets/images/login.jpg');
        background-size: cover;
        width: 100%;
        min-height: 100vh;
        padding: 1rem;
        @media screen and (max-width: 767px) {
            height: unset;
        }
        .login-form {
            width: 20rem;
            padding: 1.5rem;
            background-color: $white;
            box-shadow: 0 0 20px -1px rgba(0, 0, 0, 0.1);
            border-radius: 1rem;
            margin-bottom: 2rem;
            form {
                width: 100%;
            }
            button.login-btn {
                @include greenBtn();
                width: 100%;
            }
            .heading {
                font-size: 1rem;
                font-weight: 500;
                margin-bottom: 1rem;
                text-align: center;
            }
            .title {
                font-size: 0.9rem;
                color: $gray;
                text-align: center;
                margin-top: 1rem;
                span {
                    font-weight: 500;
                }
            }
        }
        .link__style {
            font-size: 0.8rem;
            display: block;
            margin: 0.5rem 0;
            width: 100%;
        }
    }
    .slogan-box-container {
        @include flex(center, flex-end, column);
        align-self: stretch;
        width: 100%;
        .slogan-box {
            background-color: rgba(0, 0, 0, 0.2);
            padding: 2rem;
            max-width: 30rem;
            @media screen and (max-width: 767px) {
                max-width: 20rem;
            }
            border-radius: 1rem;
            .slogan {
                color: $white;
                font-weight: 500;
                font-size: 1.5rem;
            }
        }
    }
}
