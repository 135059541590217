.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $staticColor !important;
}
.custom-input {
    margin-bottom: 1.5rem!important;
    .MuiOutlinedInput-input {
        padding: 12.5px 14px !important;
    }
    .MuiInputLabel-outlined {
        z-index: 1;
        transform: translate(14px, 15px) scale(1);
        pointer-events: none;
        font-size: 0.9rem;
        color: $inputGray;
        .MuiInputBase-formControl {
            &:hover {
                border-color: red;
            }
        }
        
    }
    .MuiOutlinedInput-notchedOutline{
        border-color: $gray;
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink{
            font-size: 0.7rem;
            background-color: $white;
            transform: translate(0.4rem, -0.4rem) scale(1);
            color: $black;
        }
}
.custom-select-input {
    .MuiInputBase-root {
        height: 40px;
    }
    .MuiInputLabel-outlined {
        z-index: 1;
        transform: translate(14px, 14px) scale(1);
        pointer-events: none;
        font-size: 2rem;
        font-size: 0.7rem;
    }
    .MuiInputBase-input.MuiOutlinedInput-input {
        height: 40px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 1rem;
    }
}
