@font-face {
    font-family: 'vivid italic';
    font-style: normal;
    font-weight: normal;
    src: local('vivid italic'), url('./assets/font/VIVALDII.woff') format('woff');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    src: local('Montserrat'), url('./assets/font/montserrat.woff') format('woff');
}
@font-face {
    font-family: 'Geomanist-Bold';
    font-style: normal;
    font-weight: bold;
    src: local('Geomanist-Bold'), url('./assets/font/Geomanist-Bold.woff') format('woff');
}
